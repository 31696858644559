import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby';

const Cases = () => (
  <StaticQuery query={graphql`
    query{
      allContentfulHomePageCase{
          edges{
            node{
              slug
              caseTitle
              caseImage{
                fluid(quality:100){
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
      }
    }
  `}

  render={data => {
    // Set ImageData.

    return (
      <section id="case-section">
        <div className="container">
          <div className="row">
            {data.allContentfulHomePageCase.edges.map((item) => {
                return(
                  <div className="column-cases no-gutters column-text">
                    <Link to={`${item.node.slug}`}>
                    <h1 className="case__title">{item.node.caseTitle}</h1>
                    <Img className="h-100" fluid={item.node.caseImage.fluid}/>
                    </Link>
                    <div className='overlay'></div>  
                  </div>
                ) 
            })}
          </div>
        </div>
      </section>

    )

  }
  }
 />

);

export default Cases;
