import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'



const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage{
          title
          heroImage{
            fluid(quality:100){
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.contentfulHomePage.heroImage.fluid
      return (
       
        <div className="container container-fluid hero-container">
          <div className="column no-gutters">
            <BackgroundImage
              Tag="div"
              className={className}
              fluid={imageData}
              backgroundColor={`#040e18`}
            >
              <h1 className="hero_title">{data.contentfulHomePage.title}</h1>
              <div className="overlay"></div>
            </BackgroundImage>
          </div>
        </div>

      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  height: 100vh;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection
