import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';




const Contact = () => (
    <StaticQuery query={graphql`
    query ContactQuery {
      contentfulHomePage{
       contactImage{
         fluid(quality:100){
          ...GatsbyContentfulFluid
         }
       }
       contactInfo{
         json
       }
     }
     }
    `}

  render={data => {
    // Set ImageData.

    return (
      <section>
        <div className="container" id="about-section">
          <div className="row">
          <div className="column no-gutters"
            data-sal="fade"
            data-sal-duration="800"
            data-sal-delay="300"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
            <Img className="h-100" fluid={data.contentfulHomePage.contactImage.fluid}/>
          </div>
            <div className="column column-text"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-easing="cubic-bezier(0.215, 0.61, 0.355, 1)">
                <h1 className="contact_title">CONTACT</h1>
              <div>{documentToReactComponents(data.contentfulHomePage.contactInfo.json)}</div>
            </div>
          </div>
        </div>
</section>
    )
  }
  }
 />

)

export default Contact;
