import React, { useState } from 'react'

const Header = () => {
  const [showNav, setShowNav] = useState(false);

  return (
    <nav className="nav-container">
      <a href="#"
        className={`nav-trigger${showNav ? ' active' : ''}`}
        onClick={() => setShowNav(!showNav)}
      >
        <span></span>
        <span></span>
        <span></span>
      </a>
      <ul className="logo">
        <h4><a className="nav-link" href="/">ida carstens</a></h4>
      </ul>
      <ul className={`nav nav-primary${showNav ? ' show' : ' hide'}`} onClick={() => setShowNav(false)}>
        <li className="nav-primary-item"><a className="nav-link" href="/">HEM</a></li>
        <li className="nav-primary-item"><a className="nav-link" href="/#case-section">CASE</a></li>
        <li className="nav-primary-item"><a className="nav-link" href="/#about-section">CONTACT</a></li>
      </ul>
    
    </nav>
  )
}

export default Header;
