/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from './header'
import StyledBackgroundSection from './hero'
import Cases from './cases'
import Contact from './contact'
import Footer from './footer'
import '../styles/app.scss'

const Layout = () => {
  return (
  <div>
    <Header/>
    <StyledBackgroundSection/>
    <Cases/>
    <Contact/>
    <Footer/>
    </div>
  )
}

export default Layout
